import React from 'react';
import { useTheme } from '@emotion/react';

import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { FoilAnimationWrapper } from 'components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// utils
import { getFontSizeOverride } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/helpers/responsiveFontSizeHelpers';
import type { PartialComponentFontView } from 'components/publicWebsiteV2/util/mappers/types';
import mapFontValuesToComponent from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';

// styles
import { getHeroTitleOverrideStyles, Title, TitleContainer } from './MultiPageHeroOnlyText.styles';

export type MultiPageHeroOnlyTextProps = {
  hasBackgroundImage?: boolean;
  title?: string;
};

const MultiPageHeroOnlyText: React.FC<MultiPageHeroOnlyTextProps> = ({
  title,
  hasBackgroundImage,
}) => {
  const {
    state: {
      components: { globalHeaderFontValues },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { MEDIA_QUERY } = useTheme();

  const { fonts } = wedding?.public_theme_v2?.components?.HERO_HOME || {};
  const heroHeaderFontStyles = fonts?.HEADER?.[1];
  const heroTitleStyles: PartialComponentFontView = {
    ...heroHeaderFontStyles,
    ...getHeroTitleOverrideStyles(Boolean(hasBackgroundImage)),
  };

  const StyledTitle = mapFontValuesToComponent({
    partial: heroTitleStyles,
    fallback: globalHeaderFontValues,
    excludeFontSize: true,
    mediaQuery: MEDIA_QUERY,
    ComponentType: Title,
  });

  const welcomeMessageFontSizeOverride = getFontSizeOverride(
    {
      desktop: 6,
      tablet: 8,
      mobile: 8,
      mobileXs: 7,
    },
    inPreview
  );

  if (!title) return null;

  return (
    <TitleContainer data-testid="MultiPageHeroOnlyText">
      <PageAnimationWrapper>
        <StyledTitle
          fontSizeOverride={welcomeMessageFontSizeOverride}
          style={{
            textShadow: hasBackgroundImage ? '0px 0px 31.3333px rgba(0, 0, 0, 0.4)' : 'none',
          }}
        >
          <FoilAnimationWrapper text={title} />
        </StyledTitle>
      </PageAnimationWrapper>
    </TitleContainer>
  );
};

export default MultiPageHeroOnlyText;
