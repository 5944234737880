import styled from '@emotion/styled';

import type { PartialComponentFontView } from 'components/publicWebsiteV2/util/mappers/types';
import {
  Z_INDEX_MAP,
  Z_INDEX_HERO_IMAGE_KEY,
  Z_INDEX_TEXT_LAYER_KEY,
} from 'components/publicWebsiteV2/util/zIndexConstants';

export const Title = styled.h1<{
  fontSizeOverride?: {
    desktop: string;
    tablet: string;
    mobile: string;
    mobileXs: string;
  };
}>`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  opacity: 1;
  overflow-wrap: break-word;
  width: 100%;

  ${({ fontSizeOverride }) =>
    fontSizeOverride?.desktop ? `font-size: ${fontSizeOverride?.desktop};` : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.tablet
        ? `${theme.MEDIA_QUERY.TABLET} { font-size: ${fontSizeOverride?.tablet}; }`
        : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.mobile
        ? `${theme.MEDIA_QUERY.MOBILE} { font-size: ${fontSizeOverride?.mobile}; }`
        : ''}
            ${({ fontSizeOverride, theme }) =>
              fontSizeOverride?.mobileXs
                ? `${theme.MEDIA_QUERY.MOBILE_XS} { font-size: ${fontSizeOverride?.mobileXs}; }`
                : ''}
`;

export const getHeroTitleOverrideStyles = (
  hasBackgroundImage: boolean
): PartialComponentFontView => {
  if (hasBackgroundImage) {
    return { color: 'FFFFFF' };
  }
  return {};
};

export const TitleContainer = styled.div`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  padding: ${props => props.theme.SPACING.LG} ${props => props.theme.SPACING.XL};
  width: 100%;

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    padding: 64px ${props => props.theme.SPACING.MD};
  }
`;

export const HeroImage = styled.div<{ backgroundImage?: string; overrideMobileHeight?: number }>`
  ${({ backgroundImage }) =>
    backgroundImage
      ? `background-image: url(${backgroundImage}); background-size: cover; background-position: center;`
      : ''}
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_MAP[Z_INDEX_HERO_IMAGE_KEY]};

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    height: ${({ overrideMobileHeight }) => `${overrideMobileHeight}px` || '100vw'};
  }
`;
